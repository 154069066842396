/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Author: nimzu (https://sketchfab.com/nimzuk)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/foxs-islands-163b68e09fcc47618450150be7785907
Title: Fox's islands
*/

import { useRef, useEffect } from "react";
import { useGLTF } from "@react-three/drei";
import {useFrame, useThree} from "@react-three/fiber";
import { a } from '@react-spring/three';

import islandScene from '../assets/3d/island.glb';

const Island = ({ isRotating, setIsRotating, setCurrentStage, ...props}) => {
  const islandRef = useRef();

  const { gl, viewport } = useThree();
  const { nodes, materials } = useGLTF(islandScene);
  
  const lastX = useRef(0);
  const rotationSpeed = useRef(0);
  const dampingFactor = 0.85;

  const handlePointerDown = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsRotating(true);

    //comprobamos si es desde un movil o un ordenador
    const clientX = e.touches ? e.touches[0].clientX : e.clientX;

    lastX.current = clientX;
  }

  const handlePointerUp = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsRotating(false);
  }

  const handleKeyDown = (e) => {
    if(e.key === 'ArrowLeft') {
      if(!isRotating) setIsRotating(true);
      islandRef.current.rotation.y += 0.01 * Math.PI;
      rotationSpeed.current = 0.0125;
    }
    else if(e.key === 'ArrowRight'){
      if(!isRotating) setIsRotating(true);
      islandRef.current.rotation.y -= 0.01 * Math.PI;
      rotationSpeed.current = -0.0125;
    }
  }

  const handleKeyUp = (e) => {
    if(e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
      setIsRotating(false);
    }
  }

  const handlePointerMove = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if(isRotating){
      const clientX = e.touches ? e.touches[0].clientX : e.clientX;

      const delta = (clientX - lastX.current) / viewport.width;
  
      islandRef.current.rotation.y += delta * 0.01 * Math.PI; //pi pq es un circulo
  
      lastX.current = clientX;
  
      rotationSpeed.current = delta * 0.01 * Math.PI;
    }

  }  
  
  useFrame(() => {
    if(!isRotating){
      rotationSpeed.current *= dampingFactor;
      if(Math.abs(rotationSpeed.current) < 0.001){
        rotationSpeed.current = 0;
      }
      islandRef.current.rotation.y += rotationSpeed.current;
    }
    else{
      const rotation = islandRef.current.rotation.y;
      const normalizedRotation =
        ((rotation % (2 * Math.PI)) + 2 * Math.PI) % (2 * Math.PI);

      // Set the current stage based on the island's orientation
      switch (true) {
        case normalizedRotation >= 5.45 && normalizedRotation <= 5.85:
          setCurrentStage(4);
          break;
        case normalizedRotation >= 0.85 && normalizedRotation <= 1.3:
          setCurrentStage(3);
          break;
        case normalizedRotation >= 2.4 && normalizedRotation <= 2.6:
          setCurrentStage(2);
          break;
        case normalizedRotation >= 4.25 && normalizedRotation <= 4.75:
          setCurrentStage(1);
          break;
        default:
          setCurrentStage(null);
      }
    }
  })

  useEffect(()=>{
    const canvas = gl.domElement;
    canvas.addEventListener('pointerdown', handlePointerDown);
    canvas.addEventListener('pointerup', handlePointerUp) ;
    canvas.addEventListener('pointermove', handlePointerMove);
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
    return () =>{
      canvas.removeEventListener('pointerdown',handlePointerDown ) ;
      canvas.removeEventListener('pointerup',handlePointerUp ) ;
      canvas.removeEventListener('pointermove', handlePointerMove);
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    }
  },[gl, handlePointerDown, handlePointerUp, handlePointerUp, handleKeyDown, handleKeyUp])

  return (
    <a.group ref={islandRef} {...props}>

<group position={[-0.427, 1.356, -5.245]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["GeoSphere001_01_-_Default_0"].geometry}
          material={materials["01_-_Default"]}
          position={[0, 0, 0.08]}
        />
      </group>
      <group
        position={[202.639, 125.648, -39.214]}
        rotation={[-Math.PI / 2, 0, 0]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["GeoSphere002_07_-_Default_0"].geometry}
          material={materials["07_-_Default"]}
          position={[4.508, -29.301, -57.099]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["Cone001_03_-_Default_0"].geometry}
        material={materials["03_-_Default"]}
        position={[21.997, 114.667, -19.238]}
        rotation={[-1.568, 0.172, -0.059]}
        scale={1.184}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["Cylinder007_03_-_Default_0"].geometry}
        material={materials["03_-_Default"]}
        position={[14.026, 66.96, -19.872]}
        rotation={[-1.556, 0.164, -0.05]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["Cylinder008_02_-_Default_0"].geometry}
        material={materials["02_-_Default"]}
        position={[21.379, 110.86, -19.238]}
        rotation={[-1.568, 0.172, -0.817]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder009__0.geometry}
        material={materials.Rectangle001__0}
        position={[13.975, 89.869, -11.249]}
        rotation={[-0.369, -0.34, -0.297]}
        scale={0.912}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder010__0.geometry}
        material={materials.Rectangle001__0}
        position={[41.27, 62.787, -10.033]}
        rotation={[-1.568, 0.172, -0.003]}
        scale={[0.624, 0.624, 0.982]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder011__0.geometry}
        material={materials.Rectangle001__0}
        position={[-14.426, 72.401, -25.601]}
        rotation={[-1.568, 0.172, 3.138]}
        scale={[0.624, 0.624, 0.982]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["Line001_08_-_Default_0"].geometry}
        material={materials["08_-_Default"]}
        position={[33.918, 71.464, -14.285]}
        rotation={[-0.067, -0.383, -0.186]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["Line002_08_-_Default_0"].geometry}
        material={materials["08_-_Default"]}
        position={[-3.798, 78.058, -22.868]}
        rotation={[3.074, 0.383, -2.956]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["Object001_02_-_Default_0"].geometry}
        material={materials["02_-_Default"]}
        position={[14.026, 66.96, -19.872]}
        rotation={[-1.556, 0.164, -0.05]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Rectangle001__0.geometry}
        material={materials.Rectangle001__0}
        position={[3.604, 61.116, -19.534]}
        rotation={[-1.587, -1.398, -1.59]}
        scale={0.633}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Rectangle002__0.geometry}
        material={materials.Rectangle001__0}
        position={[21.65, 57.972, -19.483]}
        rotation={[-1.587, -1.398, -1.59]}
        scale={0.633}
      />
      
    </a.group>
  );
}

export default Island;